import styled from "styled-components";
import colors from "../utils/colors";
import { motion } from "framer-motion";

const Card = ({ Icon, header, text }) => {
  const animateCard = {
    rest: {
      backgroundColor: colors.primary,
    },
    hover: {
      backgroundColor: colors.light,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  const animateIcon = {
    rest: {
      fill: colors.light,
      y: 100,
    },
    hover: {
      y: 0,
      fill: colors.primary,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  const animateHeader = {
    rest: {
      color: colors.light,
      y: 100,
    },
    hover: {
      y: 0,
      color: colors.black,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  const animateText = {
    rest: {
      scale: 0,
    },
    hover: {
      scale: 1,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  const Img = motion(Icon);

  return (
    <>
      <Container
        variants={animateCard}
        initial="rest"
        whileHover="hover"
        animate="rest"
      >
        <Img alt="work icon" variants={animateIcon} />
        <CardHeader variants={animateHeader}>{header}</CardHeader>
        <StyledText variants={animateText}>{text}</StyledText>
      </Container>
      <ContainerMobile>
        <Img fill={colors.primary} alt="work icon" />
        <CardHeader>{header}</CardHeader>
        <StyledText>{text}</StyledText>
      </ContainerMobile>
    </>
  );
};

const Container = styled(motion.div)`
  padding: 2rem;
  width: 20rem;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 4rem;
  min-height: 32rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  //this will animate
  background-color: ${colors.primary};

  @media only screen and (max-width: 1600px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const ContainerMobile = styled(motion.div)`
  padding: 2rem;
  width: 20rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 4rem;
  min-height: 32rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  background-color: ${colors.primary};

  display: none;

  @media only screen and (max-width: 800px) {
    display: flex;
    background-color: ${colors.light};
    /* width: 80%; */
  }
`;

const CardHeader = styled(motion.h3)`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  text-align: center;
  @media only screen and (max-width: 800px) {
    color: ${colors.black};
  }
`;

const StyledText = styled(motion.p)`
  font-size: 18px;
  color: ${colors.gray};
`;

export default Card;
