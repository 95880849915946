import styled from "styled-components";
import AboutUs from "../components/AboutUs";
import BottomHome from "../components/BottomHome";
import Hero from "../components/Hero";
import bottom from "../images/bottom.jpg";

const Home = () => {
  return (
    <Container>
      <Hero />
      <AboutUs />
      <BottomHome
        image={bottom}
        alt="young people in work"
        text="Work with us"
      />
    </Container>
  );
};

const Container = styled.div`
  //+6rem for fixed nav
  margin-top: 10.5rem;
  @media only screen and (max-width: 800px) {
    overflow: hidden;
    margin-top: 6.5rem;
  }
`;

export default Home;
