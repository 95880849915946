import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../utils/colors";
import { Header } from "../utils/styles";

const NotFound = () => {
  return (
    <Container>
      <Header>Wooops! This page does not exist.</Header>
      <StyledLink to="/">Go to home.</StyledLink>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 70vh;
  align-items: center;
  margin-top: 5.6rem;
  padding: 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  font-size: 2rem;
`;

export default NotFound;
