import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import colors from "../utils/colors";
import useScrollListener from "../hooks/useScrollListener";
import logo from "../images/logo.svg";
import { HiMenu } from "react-icons/hi";
import { HiX } from "react-icons/hi";
import { menuAnimation } from "../utils/animations";

const Nav = () => {
  const scroll = useScrollListener();
  const [menu, setMenu] = useState(false);

  const styles = {
    active: {
      visibility: "visible",
      transition: "all 0.5s",
    },
    hidden: {
      visibility: "hidden",
      transition: "all 0.5s",
      transform: "translateY(-100%)",
    },
  };

  const handleMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <StyledNav
        style={
          scroll.y > 150 && scroll.y - scroll.lastY > 0
            ? styles.hidden
            : styles.active
        }
      >
        <Link to="/">
          <img src={logo} alt="Future Education Poland" width="196" />
        </Link>
        <Navigation>
          <StyledNavLink activeclassname="active" to="/">
            HOME
          </StyledNavLink>
          <StyledNavLink activeclassname="active" to="/services">
            OUR SERVICES
          </StyledNavLink>
          <StyledNavLink activeclassname="active" to="/internships">
            INTERNSHIPS
          </StyledNavLink>
          <StyledNavLink activeclassname="active" to="/location">
            LOCATIONS
          </StyledNavLink>
          <StyledNavLink activeclassname="active" to="/contact">
            CONTACT US
          </StyledNavLink>
        </Navigation>
      </StyledNav>
      <MobileNav>
        <Link to="/">
          <img src={logo} alt="Future Education Poland" width={120} />
        </Link>
        <Hamburger onClick={handleMenu}>
          <HiMenu size="3rem" />
        </Hamburger>
        <AnimatePresence>
          {menu && (
            <Menu
              variants={menuAnimation}
              initial="initial"
              animate="animate"
              exit="initial"
            >
              <OffButton onClick={handleMenu}>
                <HiX size="3rem" />
              </OffButton>
              <StyledMobileLink onClick={handleMenu} to="/">
                HOME
              </StyledMobileLink>
              <StyledMobileLink onClick={handleMenu} to="/services">
                OUR SERVICES
              </StyledMobileLink>
              <StyledMobileLink onClick={handleMenu} to="/internships">
                INTERNSHIPS
              </StyledMobileLink>
              <StyledMobileLink onClick={handleMenu} to="/location">
                LOCATIONS
              </StyledMobileLink>
              <StyledMobileLink onClick={handleMenu} to="/contact">
                CONTACT US
              </StyledMobileLink>
            </Menu>
          )}
        </AnimatePresence>
      </MobileNav>
    </>
  );
};

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 5.6rem;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const MobileNav = styled.nav`
  display: none;
  @media only screen and (max-width: 1200px) {
    display: flex;
    position: fixed;
    top: 0;
    z-index: 50;
    background-color: ${colors.white};
    justify-content: space-between;
    padding: 0.5rem 5.6rem;
    width: 100%;
  }
  @media only screen and (max-width: 800px) {
    padding: 1rem;
  }
`;

const Hamburger = styled.button`
  color: ${colors.primary};
  background-color: ${colors.white};
  border: none;
`;

const OffButton = styled.button`
  border: none;
  background-color: ${colors.primary};
  color: ${colors.white};
  position: absolute;
  top: 0.5rem;
  right: 1rem;
`;

const Menu = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary};
  height: 100vh;
  min-width: 40vh;
  z-index: 100;
`;

const StyledMobileLink = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
  padding: 1rem;
  color: ${colors.white};
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
  font-size: 16;
  font-weight: 500;
  margin-left: 3.5rem;
  color: ${colors.primary};
`;

const Navigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Nav;
