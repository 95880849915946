const colors = {
  primary: "#1591AC",
  light: "#F5FDFF",
  white: "#FFFFFF",
  black: "#1E1E1E",
  gray: "#464545",
  secondary: "#EFD400",
};

export default colors;
