import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../utils/animations";
import hero from "../images/hero.jpg";
import colors from "../utils/colors";
import { HeaderMain, TextMain, CallToAction } from "../utils/styles";

const Hero = () => {
  return (
    <Container variants={pageAnimation} initial="initial" animate="animate">
      <HeroStyled>
        <HeaderMain>VET Mobilities in Poland</HeaderMain>
        <TextMain>
          We are a hosting organization welcoming students under Erasmus + VET
          mobilities KA1 Projects.
        </TextMain>
        <TextMain>
          The more you interact with your destination and the people in it, the
          more you immerse yourself in the cultural exchange experience, the
          more you’ll learn.
        </TextMain>
        <StyledLink to="contact">
          <CallToAction
            whileHover={{
              scale: 1.1,
              backgroundColor: colors.secondary,
              color: colors.black,
            }}
            whileTap={{
              scale: 0.8,
              backgroundColor: colors.secondary,
              color: colors.black,
            }}
          >
            Contact Us
          </CallToAction>
        </StyledLink>
      </HeroStyled>
      <ImageWrapper>
        <Image src={hero} alt="Workers" />
      </ImageWrapper>
    </Container>
  );
};

const Container = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
`;

const Image = styled.img`
  object-fit: cover;
  @media only screen and (max-width: 1650px) {
    width: 43rem;
    height: 35rem;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
    height: auto;
  }
`;

const ImageWrapper = styled.div`
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

const HeroStyled = styled.div`
  margin-left: 5.6rem;
  margin-right: 2rem;
  width: 35.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    margin-left: 0;
    margin-right: 0;
    padding: 0 1rem;
    margin-bottom: 5.1rem;
    width: auto;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  margin-top: 3.3rem;
  @media only screen and (max-width: 800px) {
    margin-top: 1rem;
  }
`;

export default Hero;
