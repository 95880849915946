import styled from "styled-components";
import { CallToAction } from "../utils/styles";
import colors from "../utils/colors";
import { Link } from "react-router-dom";
import factory from "../images/factory.jpg";

const BottomServices = ({ text }) => {
  return (
    <Container>
      <Link to="/contact">
        <StyledCallToAction
          whileHover={{
            scale: 1.1,
            backgroundColor: colors.secondary,
            color: colors.black,
          }}
          whileTap={{
            scale: 0.8,
            backgroundColor: colors.secondary,
            color: colors.black,
          }}
        >
          {text}
        </StyledCallToAction>
      </Link>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 5.1rem;
  position: relative;
  width: 100%;
  height: 48rem;
  background-image: url(${factory});
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 800px) {
    background-size: 80rem;
    margin-top: 1rem;
  }
`;

const StyledCallToAction = styled(CallToAction)`
  position: absolute;
  top: 25rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 17rem;
`;

export default BottomServices;
