const pageAnimation = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const listAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
      duration: 0.5,
    },
  },
};

const listItemAnimation = {
  initial: {
    opacity: 0,
    x: -20,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
};

const listItemMobileAnimation = {
  initial: {
    opacity: 0,
    x: -20,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const textAnim = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const imageAnim = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const menuAnimation = {
  initial: {
    x: 400,
  },
  animate: {
    x: 0,
  },
};

const ModalAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export {
  pageAnimation,
  listAnimation,
  listItemAnimation,
  textAnim,
  imageAnim,
  listItemMobileAnimation,
  menuAnimation,
  ModalAnimation,
};
