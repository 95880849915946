import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  listAnimation,
  listItemAnimation,
  listItemMobileAnimation,
  pageAnimation,
} from "../utils/animations";
import { Header, List, ListItem } from "../utils/styles";
import internships from "../images/internships.jpg";
import colors from "../utils/colors";
import { ReactComponent as Smile } from "../images/smile-side.svg";

const Internships = () => {
  const list1 = [
    "Agriculture",
    "Aircraft mechanics",
    "Car mechanics",
    "Carpentry and Wood technology",
    "CNC Mechatronics",
    "Construction",
    "Cooking",
    "Confectioner",
    "Electricians",
  ];

  const list2 = [
    "Electronics",
    "Food production",
    "Gardening",
    "Gastronomy",
    "Geodesy / Land surveying",
    "Graphic design",
    "Hairdressing",
    "Health & beauty",
    "Hotel industry",
  ];

  const list3 = [
    "IT",
    "Landscaping",
    "Marketing",
    "Photography and multimedia",
    "Shop assistant",
    "Work in a stable and with horses",
    "Veterinary",
    "Welder",
    "And other",
  ];
  const allLists = [...list1, ...list2, ...list3];

  return (
    <Container variants={pageAnimation} initial="initial" animate="animate">
      <StyledHeader>Internships</StyledHeader>
      <Img src={internships} />
      <SmallHeader>VET Sectors we work with:</SmallHeader>
      <ListContainer
        variants={listAnimation}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
      >
        <List variants={listItemAnimation}>
          {list1.map((item) => (
            <ListItem whileHover={{ scale: 1.2, originX: 0 }} key={item}>
              {item}
            </ListItem>
          ))}
        </List>
        <List variants={listItemAnimation}>
          {list2.map((item) => (
            <ListItem whileHover={{ scale: 1.2, originX: 0 }} key={item}>
              {item}
            </ListItem>
          ))}
        </List>
        <List variants={listItemAnimation}>
          {list3.map((item) => (
            <ListItem whileHover={{ scale: 1.2, originX: 0 }} key={item}>
              {item}
            </ListItem>
          ))}
        </List>
      </ListContainer>
      <MobileListContainer>
        <List
          ariants={listAnimation}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
        >
          {allLists.map((item) => (
            <ListItem
              variants={listItemMobileAnimation}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
              whileHover={{ scale: 1.2, originX: 0 }}
              key={item}
            >
              {item}
            </ListItem>
          ))}
        </List>
      </MobileListContainer>
      <CallContainer
        variants={pageAnimation}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
      >
        <Interested>
          Interested
          <Svg height={50} />?
        </Interested>
        <Button
          whileHover={{
            backgroundColor: colors.primary,
            color: colors.light,
          }}
          whileTap={{ scale: 0.8 }}
          to="/contact"
        >
          Contact Us
        </Button>
      </CallContainer>
    </Container>
  );
};

const Container = styled(motion.div)`
  //+6rem for fixed nav
  margin-top: 10.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 800px) {
    overflow: hidden;
    margin-top: 6.5rem;
  }
`;

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  @media only screen and (max-width: 800px) {
    height: 10rem;
  }
`;

const StyledHeader = styled(Header)`
  @media only screen and (max-width: 800px) {
    margin-bottom: 0.5rem;
  }
`;

const SmallHeader = styled.h3`
  font-size: 24px;
  font-weight: 500;
  margin: 4rem 0;
  color: ${colors.black};
  @media only screen and (max-width: 800px) {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
`;

const ListContainer = styled(motion.div)`
  margin-bottom: 4.5rem;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const MobileListContainer = styled(motion.div)`
  display: none;
  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
  }
`;

const CallContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 4rem;
  }
`;

const Interested = styled.h2`
  font-size: 36px;
  font-weight: 500;
  color: ${colors.black};
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 800px) {
    margin: 2rem 0;
    text-align: center;
  }
`;

const Button = styled(motion(Link))`
  text-decoration: none;
  font-size: 28px;
  font-weight: 600;
  color: ${colors.primary};
  background-color: ${colors.light};
  padding: 1rem 2rem;
  border-radius: 50px;
  box-shadow: 2px 2px 4px rgba(21, 145, 172, 0.4);
`;

const Svg = styled(Smile)`
  margin: 0 0.2rem;
`;

export default Internships;
