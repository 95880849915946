import styled from "styled-components";
import { motion } from "framer-motion";
import {
  pageAnimation,
  listAnimation,
  listItemAnimation,
  textAnim,
} from "../utils/animations";
import { Text, Header, List, ListItem } from "../utils/styles";
import about from "../images/about.jpg";
import colors from "../utils/colors";

const AboutUs = () => {
  return (
    <Container
      variants={pageAnimation}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
    >
      <ImgWrapper>
        <Img src={about} alt="About us" />
      </ImgWrapper>
      <About>
        <Wraper>
          <Header>About Us</Header>
        </Wraper>
        <Text>
          <Span>Future Education Poland</Span> is a Polish hosting organization
          welcoming students under Erasmus + VET mobilities KA1 Projects.
        </Text>
        <Text>
          We arrange: work placements / internships in companies, monitoring of
          work placements, accommodation and food, airport transfers, local
          transport, interesting cultural program of visits and excursions, help
          with ECVET documentation and certificates of work placements.
        </Text>
        <StyledText>
          Future Education Poland helps students from European countries to:
        </StyledText>
        <List
          variants={listAnimation}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
        >
          <ListItem
            variants={listItemAnimation}
            whileHover={{ scale: 1.2, originX: 0 }}
          >
            help to acquire new technical skills through internship in Poland,
          </ListItem>
          <ListItem
            variants={listItemAnimation}
            whileHover={{ scale: 1.2, originX: 0 }}
          >
            grow and boost their self-confidence, responsibility and
            interpersonal skills,
          </ListItem>
          <ListItem
            variants={listItemAnimation}
            whileHover={{ scale: 1.2, originX: 0 }}
          >
            get to know another European culture.
          </ListItem>
        </List>
        <Conclusion
          variants={textAnim}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
        >
          If you are looking for a hosting organization, we are your best
          choice!
        </Conclusion>
      </About>
    </Container>
  );
};

const Container = styled(motion.div)`
  margin-top: 5.1rem;
  display: flex;
  justify-content: space-around;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Img = styled.img`
  object-fit: cover;
  width: 41rem;
  height: 46rem;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const About = styled.div`
  width: 46rem;
  margin-right: 5.6rem;
  margin-left: 4rem;
  @media only screen and (max-width: 800px) {
    margin-right: 0;
    margin-left: 0;
    padding: 0 1rem;
    width: auto;
  }
`;

const Wraper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Span = styled.span`
  font-weight: 600;
  color: ${colors.primary};
`;

const StyledText = styled(Text)`
  font-weight: 500;
  margin-bottom: 0;
`;

const Conclusion = styled(motion.p)`
  font-size: 18px;
  font-weight: 600;
  margin-top: 4rem;
  color: ${colors.primary};
`;

export default AboutUs;
