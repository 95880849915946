import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";
import { pageAnimation } from "../utils/animations";
import colors from "../utils/colors";
import { CallToAction, Header, Text } from "../utils/styles";
import { ReactComponent as Smile } from "../images/smile.svg";
import { useState } from "react";
import React, { useRef } from "react";
import emailjs from "emailjs-com";
import { ModalAnimation } from "../utils/animations";

const Contact = () => {
  const [captcha, setCaptcha] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);

  const form = useRef();

  const onChange = () => {
    setCaptcha(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captcha) return;

    emailjs
      .sendForm(
        "service_cl49amw",
        "template_r3vx3t8",
        form.current,
        "user_7F68M4RIB9nE4Etoe32Bt"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setName("");
    setEmail("");
    setMessage("");
    setModal(true);
  };

  const handleModal = () => {
    setModal(false);
  };

  return (
    <Container variants={pageAnimation} initial="initial" animate="animate">
      <AnimatePresence>
        {modal && (
          <Modal
            variants={ModalAnimation}
            initial="initial"
            animate="animate"
            exit="initial"
          >
            <ModalMessage>
              <Text>
                Thank you for you message! <br /> We will get in touch with you
                shortly.
              </Text>
              <ModalButton onClick={handleModal}>Done</ModalButton>
            </ModalMessage>
          </Modal>
        )}
      </AnimatePresence>
      <TextWrapper>
        <StyledMainHeader>
          Any questions?
          <br /> We are always happy to help! <Svg height={50} />
        </StyledMainHeader>
        <StyledText>
          Please contact us if you have any enquiries regarding our offer. We
          will get in touch with you shortly.
        </StyledText>
        <ContactCard whileHover={{ scale: 1.2 }}>
          <CardText>Mail us at:</CardText>
          <CardTextTo>
            <StyledA href="mailto:mobilities@fepoland.eu">
              mobilities@fepoland.eu
            </StyledA>
          </CardTextTo>
          <CardText>or call us:</CardText>
          <CardTextTo>+48 530 108 101</CardTextTo>
        </ContactCard>
      </TextWrapper>
      <FormWrapper>
        <StyledHeader>Send us a message!</StyledHeader>
        <Form ref={form} onSubmit={handleSubmit}>
          <Input
            required
            type="text"
            placeholder="Name"
            value={name}
            name="user_name"
            onChange={({ target }) => setName(target.value)}
          />
          <Input
            required
            type="email"
            placeholder="E-mail adress"
            value={email}
            name="user_email"
            onChange={({ target }) => setEmail(target.value)}
          />
          <Message
            required
            type="text"
            placeholder="Message"
            value={message}
            name="message"
            onChange={({ target }) => setMessage(target.value)}
          />
          <StyledCall
            whileHover={{
              scale: 1.1,
              backgroundColor: colors.secondary,
              color: colors.black,
            }}
            whileTap={{
              scale: 0.8,
              backgroundColor: colors.secondary,
              color: colors.black,
            }}
          >
            Send Message
          </StyledCall>
          <ReCAPTCHA
            sitekey="6Lfj57cdAAAAAOTyaGTSKbFOkRWmyzIr26ztSw2l"
            onChange={onChange}
          />
        </Form>
      </FormWrapper>
    </Container>
  );
};

const Container = styled(motion.div)`
  //+6rem for fixed nav
  padding: 10.5rem 5.6rem 4.5rem 5.6rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    padding: 6.5rem 1rem 4rem 1rem;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledCall = styled(CallToAction)`
  margin-bottom: 1rem;
`;

const TextWrapper = styled.div`
  margin-right: 3rem;
  width: 38rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
    margin-right: 0rem;
    width: 90%;
  }
`;

const StyledMainHeader = styled(Header)`
  text-align: left;
  width: 34rem;
  @media only screen and (max-width: 800px) {
    width: auto;
    font-size: 32px;
  }
`;

const StyledText = styled.p`
  color: ${colors.gray};
  font-size: 24px;
  width: 34rem;
  @media only screen and (max-width: 800px) {
    width: auto;
  }
`;

const FormWrapper = styled.div`
  width: 35.5rem;
  @media only screen and (max-width: 800px) {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const StyledHeader = styled.h3`
  color: ${colors.black};
  font-size: 28px;
  font-weight: 500;
`;

const Svg = styled(Smile)`
  margin: 0 1rem;
  @media only screen and (max-width: 800px) {
    margin: 0 2rem;
    display: none;
  }
`;

const ContactCard = styled(motion.div)`
  background-color: ${colors.light};
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(21, 145, 172, 0.4);
  padding: 1rem 2.75rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 34rem;
  @media only screen and (max-width: 800px) {
    width: 90%;
    padding: 1rem 1rem;
    overflow-wrap: break-word;
  }
`;

const CardText = styled.p`
  color: ${colors.gray};
  font-size: 24px;
  @media only screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;
const CardTextTo = styled.p`
  color: ${colors.primary};
  font-size: 24px;
  margin: 0.2rem 0;
  @media only screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

const StyledA = styled.a`
  text-decoration: none;
  color: ${colors.primary};
  @media only screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

const Form = styled.form`
  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
  }
`;

const Input = styled.input`
  background-color: ${colors.light};
  border: none;
  box-shadow: 2px 2px 4px rgba(21, 145, 172, 0.4);
  border-radius: 10px;
  padding: 1rem 1.5rem;
  font-size: 18px;
  width: 100%;
  margin-top: 2rem;
  color: ${colors.gray};
  :focus {
    outline-color: ${colors.primary};
  }
  ::placeholder {
    color: #9bb2b7;
  }
`;
const Message = styled.textarea`
  background-color: ${colors.light};
  border: none;
  box-shadow: 2px 2px 4px rgba(21, 145, 172, 0.4);
  border-radius: 10px;
  padding: 1rem 1.5rem;
  font-size: 18px;
  width: 100%;
  height: 15rem;
  margin-top: 2rem;
  margin-bottom: 2.8rem;
  color: ${colors.gray};
  :focus {
    outline-color: ${colors.primary};
  }
  ::placeholder {
    color: #9bb2b7;
  }
`;

const Modal = styled(motion.div)`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ModalMessage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  width: 25rem;
  background-color: ${colors.white};
  border-radius: 10px;
`;

const ModalButton = styled.button`
  border: none;
  background-color: ${colors.primary};
  color: ${colors.light};
  padding: 1.5rem 2.8rem;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
`;

export default Contact;
