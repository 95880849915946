import styled from "styled-components";
import colors from "./colors";
import { motion } from "framer-motion";
import list from "../images/list.svg";

const HeaderMain = styled.h1`
  margin-bottom: 1.5rem;
  font-size: 48px;
  font-weight: 600;
  color: ${colors.black};
  text-align: center;
`;

const Header = styled(motion.div)`
  margin-bottom: 4rem;
  font-size: 48px;
  font-weight: 600;
  color: ${colors.black};
  text-align: center;
`;

const Text = styled.p`
  font-size: 18px;
  color: ${colors.gray};
  margin-bottom: 4rem;
`;

const LightText = styled.p`
  font-size: 18px;
  color: ${colors.light};
`;

const TextMain = styled.p`
  font-size: 18px;
  color: ${colors.gray};
  margin-bottom: 1.5rem;
`;

const CallToAction = styled(motion.button)`
  border: none;
  font-size: 28px;
  font-weight: 600;
  background-color: ${colors.primary};
  color: ${colors.light};
  padding: 1.5rem 2.8rem;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
`;
const List = styled(motion.ul)`
  list-style: none;
  padding-left: 0;
`;
const ListItem = styled(motion.li)`
  margin-top: 1rem;
  margin-right: 3.5rem;

  ::before {
    content: url(${list});
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
  }
`;

export {
  Header,
  Text,
  CallToAction,
  HeaderMain,
  TextMain,
  List,
  ListItem,
  LightText,
};
