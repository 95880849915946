import styled from "styled-components";
import { Link } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import { motion } from "framer-motion";
import { pageAnimation } from "../utils/animations";
import Card from "../components/Card";
import { Header } from "../utils/styles";
import colors from "../utils/colors";
import { ReactComponent as Work } from "../images/work.svg";
import { ReactComponent as Monitoring } from "../images/monitoring.svg";
import { ReactComponent as Accom } from "../images/accom.svg";
import { ReactComponent as Cultural } from "../images/cultural.svg";
import { ReactComponent as Document } from "../images/documents.svg";
import { ReactComponent as Plane } from "../images/plane.svg";
import factory from "../images/factory.jpg";
import BottomServices from "../components/BottomServices";

const Services = () => {
  return (
    <Container variants={pageAnimation} initial="initial" animate="animate">
      <StyledHeader>Our Services</StyledHeader>
      <CardsContainer>
        <Card
          Icon={Work}
          header="Work Placements"
          text={
            <Fragment>
              We will find adequate work placements for your students, according
              to their profiles and CVs thanks to our extensive local network of
              companies – to see the list of professions in which Erasmus + work
              placements are possible, please look at tab{" "}
              <StyledLink to="/internships">INTERNSHIPS</StyledLink>.
            </Fragment>
          }
        />
        <Card
          Icon={Monitoring}
          header="Monitoring and Support"
          text="Each group is assigned a coordinator who will be the contact person available for the group, monitoring the course of stay and internships as well as other practical support."
        />
        <Card
          Icon={Accom}
          header="Accommodation and Meals"
          text="We provide different types of accommodation and meals suit to each group needs. 
          Students are offered shared rooms and teachers accompanying the group are offered single rooms."
        />
        <Card
          Icon={Cultural}
          header="Cultural Program"
          text="We organize cultural program depending on the group's needs – in our offer you will find a wide variety of attractions to choose from!"
        />
        <Card
          Icon={Document}
          header="Documents and ECVET"
          text="We will help you with all relevant documents, eg. ECVET documents and our certificate of completion of internship as a hosting organization."
        />
        <Card
          Icon={Plane}
          header="Airport Transfer and Local Transport"
          text="We will organize an airport transfer (to and from an airport) as well as local transport."
        />
      </CardsContainer>
      <BottomServices
        image={factory}
        alt="factory workers"
        text="Get in touch"
      />
    </Container>
  );
};

export default Services;

const Container = styled(motion.div)`
  //+6rem for fixed nav
  margin-top: 10.5rem;
  @media only screen and (max-width: 800px) {
    overflow: hidden;
    margin-top: 6.5rem;
  }
`;

const StyledHeader = styled(Header)`
  @media only screen and (max-width: 800px) {
    margin-bottom: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
`;

const CardsContainer = styled.div`
  margin-left: 5.6rem;
  margin-right: 5.6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    margin-left: 1rem;
    margin-right: 1rem;
    align-items: center;
  }
`;
