import styled from "styled-components";
import colors from "../utils/colors";
import { LightText } from "../utils/styles";

const Footer = () => {
  return (
    <Container>
      <StyledText>Copyright 2021 © Future Education Poland</StyledText>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  background-color: ${colors.primary};
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    padding: 0.7rem;
  }
`;

const StyledText = styled(LightText)`
  text-align: center;
  @media only screen and (max-width: 800px) {
    padding: 1rem;
    font-size: 0.7rem;
  }
`;
export default Footer;
