import styled from "styled-components";
import { motion } from "framer-motion";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Header, Text, CallToAction } from "../utils/styles";
import { pageAnimation } from "../utils/animations";
import colors from "../utils/colors";
import ratusz from "../images/kalisz-ratusz.jpg";
import teatr from "../images/kalisz-teatr.jpg";
import noc from "../images/kalisz-noc.jpg";
import woda from "../images/kalisz-woda.jpg";
import miasto from "../images/kalisz-miasto.jpg";
import prosna from "../images/kalisz-prosna.jpg";
import warsaw1 from "../images/warsaw1.png";
import warsaw2 from "../images/warsaw2.png";
import warsaw3 from "../images/warsaw3.png";
import warsaw4 from "../images/warsaw4.png";
import warsaw5 from "../images/warsaw5.png";
import warsaw6 from "../images/warsaw6.png";
import warsaw7 from "../images/warsaw7.jpg";
import { Link } from "react-router-dom";

const Location = () => {
  return (
    <Container variants={pageAnimation} initial="initial" animate="animate">
      <StyledHeader>Locations</StyledHeader>
      <Wrapper>
        <TextWrapper>
          <StyledMediumHeader>Warsaw</StyledMediumHeader>
          <Text>
            Warsaw – the capital and the biggest city in Poland - is an
            important scientific, cultural, political and economic center. City
            lays on two sides of Vistula river, Old Town and Kings Castle sets
            on top of the boulevards of the river, creating beautiful view from
            each side of the river.
          </Text>
          <Text>
            The Old Town of Warsaw was entered on the UNESCO World Heritage List
            in 1980 and it represents every European architectural style and
            period ( from the Gothic, Renaissance, Baroque and Neoclassical
            periods). In other parts of the city are located Royal Castles,
            Royal Gardens and parks, big ZOO, historic tenement halls with
            beautiful baroque or renaissance facades and you will be able to see
            many buildings made in soc-realist style. Warsaw city center is
            contemporary – modern glass buildings are towering above older
            historical edifices, gives very modern look at the city.
          </Text>
          <Text>
            Warsaw offers a lots of attractions and interesting places to visit:
            museums, multimedia centers, educational centers for youth, sport
            facilities, cinemas, huge shopping malls, parks, live music
            concerts, a lot of seasonal events and many historical places.
          </Text>
        </TextWrapper>
        <StyledCarousel
          autoPlay
          infiniteLoop
          emulateTouch
          renderThumbs={() => false}
        >
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={warsaw2}
              alt="Old City"
            />
          </ImgContainer>
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={warsaw3}
              alt="View from the sky"
            />
          </ImgContainer>
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={warsaw4}
              alt="Mermaid of Warsaw"
            />
          </ImgContainer>
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={warsaw5}
              alt="Old City"
            />
          </ImgContainer>
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={warsaw6}
              alt="View from the sky"
            />
          </ImgContainer>
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={warsaw7}
              alt="Lazienki Palace"
            />
          </ImgContainer>
        </StyledCarousel>
      </Wrapper>
      <Wrapper>
        <TextWrapper>
          <StyledMediumHeader>Kalisz</StyledMediumHeader>
          <Text>
            We offer the internships in Kalisz, Poland (located between Berlin
            and Warsaw), a city with 110.000 inhabitants. It is one of the
            oldest cities in Poland.
          </Text>
          <Text>
            The Old Town is surrounded by beautiful parks and divided into
            quarters by Prosna river, connected by many bridges which makes this
            city beautiful and charming. Most buildings have colorful renovated
            facades and the streets are filled with restaurants and cafes.
          </Text>
          <Text>
            Most attractions are within easy reach at a walking distance.
          </Text>
          <Text>
            Near the city there are interesting places to visit and relax, such
            as lakes with water sports, a castle with a park and a ZOO. One can
            also practice water sports in Kalisz.
          </Text>
          <StyledLink to="/contact">
            <CallToAction
              whileHover={{
                scale: 1.1,
                backgroundColor: colors.secondary,
                color: colors.black,
              }}
              whileTap={{
                scale: 0.8,
                backgroundColor: colors.secondary,
                color: colors.black,
              }}
            >
              Want to know more?
            </CallToAction>
          </StyledLink>
        </TextWrapper>
        <StyledCarousel
          autoPlay
          infiniteLoop
          emulateTouch
          renderThumbs={() => false}
        >
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={ratusz}
              alt="Cityhall in Kalisz"
            />
          </ImgContainer>
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={teatr}
              alt="Theatre in Kalisz"
            />
          </ImgContainer>
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={noc}
              alt="Kalisz during the night"
            />
          </ImgContainer>
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={woda}
              alt="Water attractions"
            />
          </ImgContainer>
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={miasto}
              alt="View from the sky"
            />
          </ImgContainer>
          <ImgContainer>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={prosna}
              alt="Prosna"
            />
          </ImgContainer>
        </StyledCarousel>
      </Wrapper>
      <Space />
    </Container>
  );
};

const Container = styled(motion.div)`
  //+6rem for fixed nav
  margin-top: 10.5rem;
  @media only screen and (max-width: 800px) {
    overflow: hidden;
    padding: 0 1rem;
    margin-top: 6.5rem;
  }
`;

const Space = styled.div`
  margin-bottom: 5rem;
`;

const Wrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-around;
  margin-left: 5.6rem;
  @media only screen and (max-width: 800px) {
    flex-direction: column-reverse;
    margin-bottom: 0;
    margin-left: 0rem;
    justify-content: center;
    align-items: center;
  }
`;

const TextWrapper = styled.div`
  width: 40rem;
  margin-right: 3rem;
  @media only screen and (max-width: 800px) {
    width: auto;
    margin-right: 0;
    margin-top: 4rem;
  }
`;

const ImgContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledCarousel = styled(Carousel)`
  width: 70rem;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 800px) {
    width: auto;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 800px) {
  }
`;

const StyledHeader = styled(Header)`
  @media only screen and (max-width: 800px) {
    margin-bottom: 3rem;
  }
`;

const StyledMediumHeader = styled(Header)`
  font-size: 36px;
  @media only screen and (max-width: 800px) {
    margin-bottom: 3rem;
  }
`;

export default Location;
